.overview {
    margin-bottom: 10px;
    margin-top: 10px;
}

.tips {
    margin-bottom: 20px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    gap: 40px;
    padding: 20px;
}

.card {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 20px;
    height: 200px; /* Increased height */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-bottom: 20px; /* Space below each card */
    flex: 1 1 350px; /* Adjusts the basis for growth, allows more flexibility with increased size */
}

/* Medium devices (tablets, less than 768px) */
@media (max-width: 768px) {
    .card {
        width: calc(50% - 20px); /* 2 cards per row, considering the gap */
    }
}

/* Small devices (landscape phones, less than 576px) */
@media (max-width: 576px) {
    .card {
        width: calc(100% - 40px); /* 1 card per row */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .card {
        width: calc(33% - 40px); /* Adjusted for 3 cards per row */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .card {
        width: calc(33% - 40px); /* Ensures 3 cards per row on larger screens */
    }
}





/* This will only apply to the card being hovered over */
.card:hover {
    transform: scale(3.5); /* Slightly increase the size of the card */
    opacity: 1; /* Ensure the hovered card is fully opaque */
    z-index: 10; /* Make sure the hovered card is above others */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhance the shadow for a "lifting" effect */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transitions for transform and shadow */
}
.card-container .card {
    transition: transform 0.3s, opacity 0.3s, box-shadow 0.3s; /* Smooth transitions for transform, opacity, and shadow */
}

.card-container .card:hover {
    transform: scale(1.05); /* Slightly increase the size of the card */
    opacity: 1; /* Make the hovered card fully opaque */
    z-index: 10; /* Ensure the hovered card is above others */
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhance the shadow for a 'lifting' effect */
}
.card-container .card:not(:hover) {
    opacity: 0.9; /* Reduce opacity of non-hovered cards to make them appear blurred */
}

.dashboard-container {
    display: flex;
    height: 100vh;
}


.tips{
    margin-top: 5%;
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 200px; /* Ensure the unit is specified */
    height: 100%;
    background-color: #0056b3;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.bottom-buttons {
    margin-top: 100%;
    
}



.main-content {
    flex-grow: 1;
    padding: 30px;
    margin-left: 270px;
    margin-bottom: 30px;
}

.sidebar button {
    padding: 10px;
    margin: 5px 0;
    cursor: pointer;
    text-align: left;
    background-color: transparent; /* Make button background transparent */
    color: white; /* Set text color to white */
    border: 2px solid #0056b3; /* Set border color and width */
    border-radius: 15px; /* Set border radius for rounded corners */
    transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transition for colors */
}

.sidebar button:hover {
    background-color: #e4e4e4; /* Set a background color on hover */
    color: #0056b3; /* Change text color on hover for better visibility */
    border-color: #0056b3; /* Change border color on hover to match the background */
}

/* Style for active button */
.sidebar button.active {
    background-color: #ddd;
    color: #0056b3; /* Dark text color for active button */
}
.icon-button {
    font-size: 20px; /* Adjust size as needed */
    /* Additional styling for the icon button */
}


/* ... Tender Generation ... */

.tender-generation {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}


.wizard, .upload-area-tender, .ai-suggestions {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}
h1{
    color: #0056b3;
}

h2 {
    color: #f9f9f9;
}


.drop-zone-tender {
    border: 2px dashed #ccc;
    padding: 30px;
    text-align: center;
    color: #999;
    cursor: pointer;
    margin-top: 10px;
}

.drop-zone:hover {
    background-color: #e8e8e8;
}


@media (max-width: 768px) {
    .sidebar {
        position: static;
        height: auto;
        width: 100%;
    }
    .main-content {
        margin-left: 0;
    }
    .tender-generation {
        padding: 10px;
    }

    .wizard, .upload-area, .ai-suggestions {
        padding: 10px;
    }

    .drop-zone {
        padding: 20px;
    }
    

}


/*---Chatbot 2---*/
.tender-contract-comparison {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.title {
    text-align: center;
    color: #333;
}

.description {
    text-align: center;
    margin-bottom: 40px;
    color: #666;
}

.upload-instructions, .comparison-results {
    margin-bottom: 30px;
}

.upload-areas {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
}

.upload-area {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
}

.drop-zone {
    border: 2px dashed #ccc;
    padding: 30px;
    color: #999;
    cursor: pointer;
}

.drop-zone:hover {
    background-color: #e8e8e8;
}


@media (max-width: 768px) {
    .upload-areas {
        flex-direction: column;
    }

    .upload-area {
        width: 100%;
        margin-bottom: 20px;
    }
}


/*----Third Bot*/
.legal-chatbot-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}

.title {
    color: #333;
}

.description-chatbot {
    color: #666;
    margin-bottom: 20px;
}

.chatbot-interface {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 30px;
}

.welcome-message {
    background-color: #e8e8e8;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.chat-form {
    display: flex;
    gap: 10px;
}

.chat-input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submit-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-btn:hover {
    background-color: #0056b3;
}

.faq-section {
    text-align: left;
}

@media (max-width: 768px) {
    .chat-form {
        flex-direction: column;
    }

    .submit-btn {
        width: 100%;
    }
}


/*------------------Chatbot 4---------*/
.contract-generation {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}


.wizard, .template-gallery, .ai-benefits {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

.template-gallery {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
}

.template-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    background-color: #fff;
}

@media (max-width: 768px) {
    .template-gallery {
        grid-template-columns: 1fr;
    }
}


/*-----Setting------*/
.settings-profile {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}



.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-button {
    padding: 10px 20px;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    cursor: pointer;
    margin-right: 5px;
}

.tab-button.active {
    background-color: #ddd;
}

.tab-content {
    text-align: left;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .tabs {
        flex-direction: column;
    }

    .tab-button {
        width: 100%;
        margin-bottom: 5px;
    }
}


/*---------Keyword research--------*/

.keyword-research-tool {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    text-align: center;
}


.search-section {
    margin-bottom: 30px;
}

.keyword-input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 70%;
    margin-right: 10px;
}

.search-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #0056b3;
}

.tutorial-section, .insights-section {
    text-align: left;
    margin-top: 30px;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
}

@media (max-width: 768px) {
    .keyword-input {
        width: 60%;
    }
}





/*

First page CSS

*/
.language-translator {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.title {
    text-align: center;
    color: #333;
}

.description {
    text-align: center;
    margin-bottom: 30px;
    color: #666;
}

.translator-container {
    display: grid;
    gap: 20px;
}

.input-area, .translated-text {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.input-textarea{
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-family: 'Calibri', Arial, sans-serif;
    border-radius: 4px;
    min-height: 150px;
    resize: vertical;
    font-size: 24px;
}

.output-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    font-family: 'Calibri', Arial, sans-serif;
    border-radius: 4px;
    text-align: justify;
    min-height: 150px;
    resize: vertical;
    font-size: 24px;
}

.settings {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.language-select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.translate-btn, .copy-btn {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.translate-btn:hover, .copy-btn:hover {
    background-color: #025C78;
}

@media (max-width: 600px) {
    .settings {
        flex-direction: column;
    }
}




/*

    Speech to text Css

*/

/* style.css */
.tender-contract-comparison {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    color: #333;
    text-align: center;
}

.description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.upload-instructions, .drop-zone {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.drop-zone {
    cursor: pointer;
}

.drop-zone p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

/* style.css */
.tender-contract-comparison {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.title {
    color: #333;
    text-align: center;
}

.description {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
}

.upload-instructions, .drop-zone {
    background-color: #f9f9f9;
    border: 2px dashed #ddd;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}

.drop-zone {
    cursor: pointer;
}

.drop-zone p {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.tButton {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.tButton:hover {
    background-color: #025C78;
}

textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
}

@media (max-width: 768px) {
    .tender-contract-comparison {
        padding: 10px;
    }

    .title, .description, .upload-instructions h2, .drop-zone p {
        text-align: left;
    }
}


button:hover {
    background-color: #0056b3;
}

textarea {
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: none;
}

@media (max-width: 768px) {
    .tender-contract-comparison {
        padding: 10px;
    }

    .title, .description, .upload-instructions h2, .drop-zone p {
        text-align: left;
    }
}



/*

    Chatbot update

*/
.chatbot-update-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.chatbot-update-container h1 {
    color: #333;
    text-align: center;
}

.chatbot-update-container p {
    color: #666;
    text-align: center;
}

.update-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.file-input {
    border: 1px solid #ccc;
    padding: 8px;
    width: 100%;
}

.submit-btn {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.submit-btn:hover {
    background-color: #025C78;
}

.file-list {
    list-style: none;
    padding: 0;
}

.file-list li {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.delete-btn {
    background-color: #ff4545;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.delete-btn:hover {
    background-color: #ff2121;
}



/*

    Table

*/
.table-container {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 30px;
    overflow-x: auto;
  }
  
.table {
    width: 100%;
    
    border-collapse: collapse;
}
  
.table th,
.table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}
  
.table th {
    background-color: #f2f2f2;
}
  
.table tr:hover {
    background-color: #f5f5f5;
}

.delete-btn {
    padding: 5px 10px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
.delete-btn:hover {
   background-color: #ff3333;
  }
  
  

/*
  
    Digital aalim chat view
  
*/
  .digital-aalim-chats {
    padding: 20px;
  }
  
  .settings-profile {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .chat-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .chat-controls input {
    margin: 0 10px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .chat-controls button {
    padding: 5px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .chat-controls button:hover {
    background-color: #45a049;
  }
  
  .chats-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .chats-table th,
  .chats-table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }
  
  .chats-table th {
    background-color: #f2f2f2;
  }
  
  .chats-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  

  /*
  
    Update password

  */

 /* Base styles for setting cards container and cards */
.setting-cards-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .setting-card {
    background: #fff;
    border-radius: 8px;
    /* Enhance the shadow for a 3D effect */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    flex-basis: calc(50% - 10px);
    max-width: calc(50% - 10px);
    box-sizing: border-box;
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition for hover effect */
  }
  
  /* Scale shadow on hover for interaction feedback */
  .setting-card:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25);
  }
  
  /* Style titles in cards */
  .setting-card .title {
    margin-bottom: 15px;
    color: #333; /* Prefer a darker color for professional look */
    font-size: 1.25rem; /* Slightly larger title */
  }
  
  /* Form and input styles */
  .setting-card form {
    display: flex;
    flex-direction: column;
  }
  
  .setting-card input[type="text"],
  .setting-card input[type="email"],
  .setting-card input[type="password"],
  .setting-card select {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;

  }
  
  .setting-card .create-user-form button,
  .setting-card .update-form button {
    padding: 10px;
    margin-top: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .setting-card .create-user-form button:hover,
  .setting-card .update-form button:hover {
    background-color: #025C78;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .setting-card {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
  
  /* Password field icon styling */
  .password-field {
    position: relative;
    display: flex; /* Use flex to align icon with the input */
    align-items: center;
    width: 100%; /* Center align the icon */
  }
  
  .password-field input {
    flex-grow: 1; /* Input should grow and fill the space */
  }
  
  .password-field i {
    margin-left: -30px; /* Position the icon inside the input field */
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 39%;
    transform: translateY(-50%);
  }
  


/* Deploy Form Styles --------------------------------------------------------------------------------------------- */

.deploy-container {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    max-height: 100vh;
    /*overflow-y: auto;*/
}

.deploy-container h2 {
    font-size: 16px;
    color: inherit;
}


.deploy-form {
    max-height: 600px;
}

.deploy-form .ant-input {
    border-radius: 6px;
    padding: 8px;
    background-color: #f5f5f5;
    color: #000;
}

[data-theme="dark"] .deploy-form .ant-input {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.deploy-form .ant-textarea {
    border-radius: 6px;
    padding: 8px;
    background-color: #f5f5f5;
    color: #000;
}

[data-theme="dark"] .deploy-form .ant-textarea {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.deploy-form .ant-textarea,
.deploy-form .ant-textarea {
    border-radius: 6px;
    padding: 8px;
    background-color: #f5f5f5;
    color: #000;
}

[data-theme="dark"] .deploy-form .ant-input,
[data-theme="dark"] .deploy-form .ant-textarea {
    background-color: #333;
    color: #fff;
    border-color: #555;
}

.deploy-form .ant-upload-drag {
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    background-color: #fafafa;
    text-align: center;
    padding: 12px;
}

.deploy-form .ant-upload-drag:hover {
    border-color: #40a9ff;
}

[data-theme="dark"] .deploy-form .ant-upload-drag {
    background-color: #3c3c3c;
    border-color: #444;
}

/* Icon Preview Styles */
.icon-preview {
    display: block;
    margin: 10px auto;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #d9d9d9;
}

[data-theme="dark"] .icon-preview {
    border-color: #555;
}

/* Button Styles */
.deploy-form .ant-btn {
    border-radius: 6px;
}

.deploy-form .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
}

.deploy-form .ant-btn-primary:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
}

[data-theme="dark"] .deploy-form .ant-btn-primary {
    background-color: #1e90ff;
    border-color: #1e90ff;
}

[data-theme="dark"] .deploy-form .ant-btn-primary:hover {
    background-color: #3c9eff;
    border-color: #3c9eff;
}


.icon-input-label {
    display: block;
    width: 100px; /* Increased size */
    height: 100px; /* Increased size */
    border-radius: 50%;
    background-color: #f5f5f5;
    border: 2px solid #212121;
    margin: 20px auto; /* Adjusted for better centering */
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-size: cover; /* Ensure the image covers the area */
    background-position: center;
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 24 24'%3E%3Cpath d='M10 8H8v2H6v2h2v2h2v-2h2v-2h-2z'/%3E%3Cpath d='M5 2C3.89 2 3 2.9 3 4v16c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V4c0-1.1-.89-2-2-2H5zm0 2h14v16H5V4z'/%3E%3C/svg%3E"); /* Default upload icon visible */
}


.icon-input-label:hover,
.icon-input-label:focus-within {
    background-color: #f5f5f5; /* Lighter background on hover */
    border-color: #b0b0b0; /* Darker border on hover */
}

.cancel-upload-btn {
    display: block;
    margin: 10px auto;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    background-color: #ff6347; /* Tomato red, change as needed */
    color: black;
    border-radius: 5px;
}

.icon-input {
    opacity: 0; /* Hide the actual input */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}


[data-theme="dark"] .upload-instructions {
    color: #ffffff;
}


/* Manage Form Styles --------------------------------------------------------------------------------------------- */
/* Table container in dark theme */
[data-theme="dark"] .manage-chatbots-container {
    background-color: #333333; /* Dark theme background */
    color: #ffffff; /* Light text for contrast */
}

/* Table background and borders in dark mode */
[data-theme="dark"] .bots-table .ant-table-thead > tr > th {
    background-color: #171717; /* Dark header background */
    color: #ffffff; /* Light header text */
    /*border-color: #353740; !* Dark border color *!*/
    border: none;
}

[data-theme="dark"] .bots-table .ant-table-tbody > tr > td {
    background-color: #333333; /* Darker table rows */
    color: #ffffff; /* Light table text */
    /*border-color: #353740; !* Dark border color *!*/
}

/* Hover effect for rows in dark mode */
[data-theme="dark"] .bots-table .ant-table-tbody > tr:hover > td {
    background-color: #444444; /* Highlight row */
    color: #ffffff; /* Ensure text contrast */
}

/* Tags in dark theme */
[data-theme="dark"] .ant-tag {
    background-color: #444444; /* Dark tag background */
    color: #ffffff; /* Light tag text */
    /*border: none; !* Remove border for a cleaner look *!*/
}

/* Tooltip in dark theme */
[data-theme="dark"] .ant-tooltip-inner {
    background-color: #444444; /* Tooltip background */
    color: #ffffff; /* Tooltip text color */
    /*border: none; !* Remove border *!*/
}

/* Buttons in dark theme */
[data-theme="dark"] .ant-btn {
    background-color: #333333; /* Button background */
    color: #ffffff; /* Button text color */
    /*border-color: #555555; !* Button border *!*/
}

[data-theme="dark"] .ant-btn:hover {
    background-color: #444444; /* Button hover background */
    color: #ffffff; /* Button hover text */
    /*border-color: #666666; !* Button hover border *!*/
}


.logo-container{
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
}

.app-logo{
    width: 100px;
    height: 50px;
    align-items: center;
    justify-content: center;
}


.highlighted-row {
  background-color: #e6f7ff !important;
}



.h1-logo{

    color: #FFFFFF;
    font-size: 20px;
}

