/* Overall container: split into left and right panels */
.login-container {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

/* Left side: White panel with the form */
.login-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

/* Wrapper to limit width of the form */
.login-form-wrapper {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  padding: 40px 20px;
}

/* Titles and subtitles */
.login-title {
  margin-bottom: 8px;
  font-weight: 600;
  text-align: left;
}
.login-subtitle {
  margin-bottom: 24px;
  color: #666;
  text-align: left;
}

/* Forgot password link */
.login-forgot {
  text-align: right;
  margin-bottom: 16px;
}
.login-forgot a {
  color: #1890ff;
  text-decoration: none;
  font-size: 14px;
}
.login-forgot a:hover {
  text-decoration: underline;
}

/* Sign in button */
.login-button {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
}

/* "New to Swadesi Admin? Sign up!" section */
.login-new-account {
  margin-top: 24px;
  text-align: center;
  color: #666;
  font-size: 14px;
}
.login-new-account a {
  color: #1890ff;
  text-decoration: none;
}
.login-new-account a:hover {
  text-decoration: underline;
}

/* Right side: gradient background with simple circular shapes */
.login-right {
  flex: 1;
  background: linear-gradient(
    135deg,
    rgba(18, 194, 233, 1) 0%,
    rgba(196, 113, 237, 1) 50%,
    rgba(246, 79, 89, 1) 100%
  );
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left {
    width: 100%;
    order: 2; /* Move form below gradient on mobile if desired */
  }

  .login-right {
    width: 100%;
    height: 240px; /* Show a smaller gradient area at the top */
    order: 1;
  }

  .login-form-wrapper {
    max-width: 100%;
    padding: 40px;
  }
}


.app-logo-login{
    margin: 100px ;
    width: 80%;
    height: 80%;
    align-items: center;
    justify-content: center;
}

